import Link from 'next/link';
import { Component } from 'react';
import { Col, Row } from '@bridebook/ui';
import ComponentStyles from 'components/generic/NotFound.styl';
import { imgixBaseURL } from 'lib/utils';

interface IProps {
  status: number;
  statusCode?: number;
}

export default class Error extends Component<IProps> {
  static getInitialProps({ res, jsonPageRes }) {
    const statusCode = res ? res.statusCode : jsonPageRes ? jsonPageRes.status : null;
    return { statusCode };
  }

  render() {
    const { statusCode } = this.props;
    const title = `Hmm, something is not quite right with the page you're
    looking for... ${statusCode === 404 ? '' : 'Please refresh the page.'}`;

    return (
      <div>
        <section className="notfound-container container">
          <style jsx global>
            {ComponentStyles}
          </style>
          <Row>
            <Col xs={12}>
              <h1>{title}</h1>
              <figure className="img-container">
                <img
                  src={`${imgixBaseURL}/assets/404-not-found.jpg?auto=compress`}
                  alt="404 page not found"
                />
                <figcaption>By Fiona Kelly Wedding Photography</figcaption>
              </figure>

              <p>
                Don't cry about it - Head back to the{' '}
                <Link href="/" legacyBehavior>
                  <a>homepage</a>
                </Link>
              </p>
            </Col>
          </Row>
          <p className="server-error">
            {statusCode
              ? `An error ${statusCode} occurred on server`
              : 'An error occurred on client'}
          </p>
        </section>
      </div>
    );
  }
}
