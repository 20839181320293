import css from 'styled-jsx/css';

const styles = css.global`.notfound-page {
  margin-top: 20px;
  padding-top: 0;
  transition: padding-top 0.25s ease;
}
.not-found-wrap .dashboard {
  min-height: initial;
  padding-top: 40px;
  padding-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .not-found-wrap .dashboard {
    padding-top: 60px;
  }
}
.notfound-container {
  text-align: center;
  padding: initial;
  font-family: "Open Sans", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
.notfound-container .server-error {
  color: #fff;
}
.notfound-container.container {
  padding: initial;
}
.notfound-container .row {
  margin-left: 20px;
  margin-right: 20px;
}
.notfound-container .row h1 {
  margin-bottom: 30px;
}
.notfound-container figure {
  width: 100%;
  max-width: 660px;
  display: block;
  margin: 0 auto 12px auto;
}
.notfound-container figure img {
  width: 100%;
}
.notfound-container figure figcaption {
  position: relative;
  padding-left: 10px;
  margin-top: -40px;
  color: #fff;
  background-color: rgba(22,46,62,0.7);
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 14px;
  text-align: left;
}
`;

export default styles;